import React, { useMemo, useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { ATS_PATHS } from "routes";
import { WIDTHS, COLORS, COLORSV2 } from "constants/style";
import { BREAKPOINTS, CAN_HOVER } from "constants/breakpoints";
import useGlobalContext from "contexts/globalContext/GlobalContext";
import useHistoryContext from "contexts/historyContext/HistoryContext";
import mainMenuGenerator from "constants/menus/main";
import MenuRow from "components/ATSNavbar/MenuRow";
import BurguerMenu from "components/ATSNavbar/BurguerMenu";
// import { AWS_CDN_URL } from "constants/api";
import CompanySelect from "components/ATSNavbar/CompanySelect";
import useDropdown from "hooks/useDropdown";
import UpgradeModal from "modals/UpgradeModal";
import { allowAdminAccount } from "sharedComponents/AllowAdminFunc";
// import Logo from "assets/imgs/hirebuddy_white.png";

let timeout;
let intervalScroll;

const subscriptionStatusesView = {
	free: true,
	active: true,
	trial: true,
	cancelled: true,
	starter: true,
	incomplete: false,
	incomplete_expired: false,
	past_due: false,
	unpaid: false,
	lifetime: true,
};

const ATSNavbar = ({ children }) => {
	const store = useGlobalContext();
	const historyStore = useHistoryContext();
	const navNode = useRef();
	const showATSNavBar = useMemo(() => {
		return (
			ATS_PATHS[historyStore.current?.match.path] &&
			Object.values(store.main_endpoints_load).every((val) => val)
		);
	}, [historyStore.current, store.main_endpoints_load]);
	const allowCompanyView = useMemo(() => {
		if (
			store.subscription_settings &&
			subscriptionStatusesView[store.subscription_settings?.status]
		) {
			// console.log("Active Subscription");
			return true;
		} else if (!ATS_PATHS[historyStore.current?.match.path]) {
			return true;
		} else return allowAdminAccount(store.session) ? true : false;
	}, [store.subscription_settings, store.session]);

	const [hover, setHover] = useState(false);
	const [expanded, setExpanded] = useState(false);
	const { node, showSelect, setShowSelect } = useDropdown();
	const expandedView = useMemo(
		() => (showSelect ? "large-expanded" : hover || expanded ? "expanded" : ""),
		[hover, expanded, showSelect]
	);
	const [menu, setMenu] = useState([]);
	const menuNode = useRef();
	const [initialPageLoad, setInitialPageLoad] = useState(false);
	const [scrollSettings, setScrollSettings] = useState({});

	//GENERATE ALL MENUS
	useEffect(() => {
		if (store.company && store.role) {
			setMenu(mainMenuGenerator(store));
		}
	}, [store.company, store.role, store.subscription_settings]);

	const enterHover = () =>
		!hover && !expanded
			? (timeout = setTimeout(function () {
					setHover(true);
			  }, 500))
			: null;
	const leaveHover = () => {
		hover ? setHover(false) : null;
		showSelect && !expanded ? setShowSelect(false) : null;
		if (timeout) {
			clearTimeout(timeout);
		}
	};
	const toggleExpand = () => {
		let newExp = !expanded;
		setExpanded(newExp);
		if (newExp === false) {
			setHover(false);
		}
	};

	useEffect(() => {
		if (initialPageLoad) {
			store.dispatch({
				type: "UPDATE_ATS_NAVBAR_SIZE",
				payload: expanded ? WIDTHS.left_navbar_extended : WIDTHS.left_navbar_compact,
			});
			localStorage.setItem("navbar_positioning", JSON.stringify({ expanded }));
		}
	}, [expanded]);

	useEffect(() => {
		setInitialPageLoad(true);
		let localStorageView = JSON.parse(localStorage.getItem("navbar_positioning"));
		if (localStorageView && localStorageView.expanded) {
			setExpanded(true);
		}
	}, []);

	const handleClick = (e) => {
		let container = document.getElementById("ats-navbar-container");
		if (!container?.contains(e.target)) {
			setExpanded(false);
		}
	};

	useEffect(() => {
		if (!CAN_HOVER) {
			document.addEventListener("mousedown", handleClick);
			return () => {
				document.removeEventListener("mousedown", handleClick);
			};
		}
	}, [CAN_HOVER]);

	useEffect(() => {
		if (menuNode && menuNode.current) {
			let scrollSett = {
				scrollHeight: menuNode.current.scrollHeight,
				clientHeight: menuNode.current.clientHeight,
				scrollTopMax: menuNode.current.scrollHeight - menuNode.current.clientHeight,
				showTopArrow: false,
				showBottomArrow: false,
			};
			if (scrollSett.scrollHeight > scrollSett.clientHeight) {
				scrollSett.showBottomArrow = true;
			}
			setScrollSettings(scrollSett);
		}
	}, [menuNode.current, menu, expandedView]);

	const scrollMenu = (type) => {
		let menuItem = menuNode.current;
		if (type === "down-all") {
			menuItem.scroll(0, scrollSettings.scrollHeight);
			setScrollSettings({ ...scrollSettings, showTopArrow: true, showBottomArrow: false });
			clearInterval(intervalScroll);
		} else if (type === "up-all") {
			menuItem.scroll(0, 0);
			setScrollSettings({ ...scrollSettings, showTopArrow: false, showBottomArrow: true });
			clearInterval(intervalScroll);
		} else if (type === "down-over") {
			intervalScroll = setInterval(function () {
				let newPos = menuItem.scrollTop + 4;
				menuItem.scroll(0, newPos);
				if (newPos >= scrollSettings.scrollTopMax) {
					setScrollSettings({ ...scrollSettings, showTopArrow: true, showBottomArrow: false });
					clearInterval(intervalScroll);
				} else if (newPos > 0) {
					setScrollSettings({ ...scrollSettings, showTopArrow: true });
				}
			}, 10);
		} else if (type === "up-over") {
			intervalScroll = setInterval(function () {
				let newPos = menuItem.scrollTop - 4;
				menuItem.scroll(0, newPos);
				if (newPos <= 0) {
					setScrollSettings({ ...scrollSettings, showTopArrow: false, showBottomArrow: true });
					clearInterval(intervalScroll);
				} else if (newPos < scrollSettings.scrollTopMax) {
					setScrollSettings({ ...scrollSettings, showBottomArrow: true });
				}
			}, 10);
		} else {
			setScrollSettings({
				...scrollSettings,
				showTopArrow: menuItem.scrollTop > 0,
				showBottomArrow: menuItem.scrollTop < scrollSettings.scrollTopMax,
			});
		}
	};

	if (showATSNavBar) {
		return (
			<FlexContainer>
				<NavBarContainer className={`${expanded ? "expanded" : ""}`} id="ats-navbar-container">
					<NavBar
						onMouseEnter={enterHover}
						onMouseLeave={leaveHover}
						className={`leo-pt-15 ${expandedView} ${hover ? "hovering" : ""}`}
						ref={navNode}
					>
						<BurguerContainer>
							<BurguerButton className="leo-pl-30 leo-flex-center-center" onClick={toggleExpand}>
								<IconContainer className="leo-flex-center-center">
									<BurguerMenu size="desktop" expanded={expanded} />{" "}
								</IconContainer>
								{/* {expandedView && (
									<>
										<img src={`${AWS_CDN_URL}/icons/Logo.svg`} alt="BrandLogo" />
										<img src={Logo} alt="BrandLogo" />
									</>
								)} */}
							</BurguerButton>
						</BurguerContainer>
						<CompanySelect
							expandedView={expandedView}
							store={store}
							node={node}
							showSelect={showSelect}
							setShowSelect={setShowSelect}
						/>
						<div className="leo-relative">
							{" "}
							{scrollSettings.showTopArrow && (
								<ScrollButton
									onClick={() => scrollMenu("up-all")}
									onMouseEnter={() => scrollMenu("up-over")}
									onMouseLeave={() => clearInterval(intervalScroll)}
								>
									<i className="fas fa-chevron-up"></i>
								</ScrollButton>
							)}
							<MenusContainer
								id="menu-container-ats-navbar"
								ref={menuNode}
								onScroll={() => scrollMenu()}
							>
								{menu.map((menuTab, ix) => (
									<MenuRow
										menuTab={menuTab}
										key={`menu-item-${ix}`}
										expandedView={expandedView}
										store={store}
										historyStore={historyStore}
									/>
								))}
								{expandedView && (
									<NavLogo>
										{/* <img src={`${AWS_CDN_URL}/logos/plac-d-white-logo.png`} alt="BrandLogo" /> */}
										{/* Rebranding change: Updated logo URL for Talent-Hero
										 */}
										<img src={process.env.REACT_APP_MAIN_LOGO} alt="BrandLogo" />
									</NavLogo>
								)}
							</MenusContainer>
							{scrollSettings.showBottomArrow && (
								<ScrollButton
									className="down-background"
									onClick={() => scrollMenu("down-all")}
									onMouseEnter={() => scrollMenu("down-over")}
									onMouseLeave={() => clearInterval(intervalScroll)}
								>
									<i className="fas fa-chevron-down"></i>
								</ScrollButton>
							)}
						</div>
					</NavBar>
				</NavBarContainer>
				{allowCompanyView && store.subscription_settings ? (
					children
				) : store.subscription_settings && !allowAdminAccount(store.session) ? (
					<UpgradeModal noModal={true} store={store} show={store.subscription_settings} />
				) : null}
			</FlexContainer>
		);
	} else if (allowCompanyView && children) {
		return <>{children}</>;
	} else if (store.subscription_settings && !allowAdminAccount(store.session)) {
		return <UpgradeModal store={store} noModal={true} show={store.subscription_settings} />;
	} else return null;
};

const FlexContainer = styled.div`
	display: flex !important;

	/* .os-theme-thin-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle::before {
		background: white !important;
	} */
`;

const NavBarContainer = styled.div`
	width: ${WIDTHS.left_navbar_compact};
	min-width: ${WIDTHS.left_navbar_compact};
	transition: all 300ms;

	&.expanded {
		width: ${() => (CAN_HOVER ? WIDTHS.left_navbar_extended : WIDTHS.left_navbar_compact)};
		min-width: ${() => (CAN_HOVER ? WIDTHS.left_navbar_extended : WIDTHS.left_navbar_compact)};
	}

	@media ${BREAKPOINTS.for_phone_only} {
		width: 0px;
		min-width: 0px;

		&.expanded {
			width: 0px;
			min-width: 0px;
		}
	}
`;
// &.large-expanded {
// 	width: ${WIDTHS.left_navbar_large};
// 	min-width: ${WIDTHS.left_navbar_large};
// }

const NavBar = styled.div`
	width: ${WIDTHS.left_navbar_compact};
	height: 100vh;
	min-height: 100%;
	/* background: linear-gradient(0deg, ${COLORSV2.blue} -0.01%, ${COLORSV2.nave_blue} 100.01%); */
	background: ${COLORSV2.nave_blue};
	transition: all 300ms;
	position: fixed;
	top: 0;
	z-index: 11;
	overflow: hidden;

	&.expanded {
		width: ${WIDTHS.left_navbar_extended};
	}

	&.large-expanded {
		width: ${WIDTHS.left_navbar_large};
	}

	&.hovering {
		box-shadow: 2px 1px 19px rgba(0, 0, 0, 0.15);
	}

	@media ${BREAKPOINTS.for_phone_only} {
		width: 0px;

		&.expanded,
		&.large-expanded {
			box-shadow: 2px 1px 19px rgba(0, 0, 0, 0.15);
		}
	}
`;

const MenusContainer = styled.div`
	max-height: calc(100vh - 155px);
	overflow-y: scroll;

	/* Hide scrollbar for Chrome, Safari and Opera */
	&::-webkit-scrollbar {
		display: none;
	}

	/* Hide scrollbar for IE, Edge and Firefox */
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
`;

const BurguerContainer = styled.div`
	width: 35px;
	height: 23px;
	width: 100%;
	margin-bottom: 62px;
	display: flex;
	align-items: center;

	img {
		height: 30px;
	}
`;

const BurguerButton = styled.button`
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: ${COLORS.text_medium_grey};

	img {
		margin-right: 10px;
		width: 110px;
		object-fit: contain;
	}
	@media ${BREAKPOINTS.for_phone_only} {
		position: fixed;

		img {
			display: none;
		}
	}
`;

const IconContainer = styled.div`
	width: 35px;
	height: 23px;
	margin-right: 10px;
`;

const NavLogo = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100px;
	img {
		width: 150px;
	}
`;

const ScrollButton = styled.button`
	position: absolute;
	width: 100%;
	color: #ffffff42;
	font-size: 25px;
	/* background: ${COLORS.primary_theme_blue}; */
	background: linear-gradient(180deg, rgb(145, 165, 214) 0%, rgba(17, 202, 214, 0) 100%);
	padding: 15px 0px 10px 0px;
	z-index: 1;

	&:hover {
		/* background: linear-gradient(180deg, rgba(251, 251, 251, 0.13) 0%, rgba(42, 55, 68, 0) 100%); */
		background: linear-gradient(
			180deg,
			rgba(251, 251, 251, 0.1) 0%,
			rgba(17, 202, 214, 0) 70%,
			rgba(17, 202, 214, 0) 100%
		);
	}

	&.down-background {
		bottom: 0;
		background: linear-gradient(0deg, rgb(0, 113, 188) 0%, rgba(0, 113, 188, 0) 100%);

		&:hover {
			/* background: linear-gradient(0deg, rgba(251, 251, 251, 0.13) 0%, rgba(42, 55, 68, 0) 100%); */
			background: linear-gradient(
				0deg,
				rgba(251, 251, 251, 0.1) 0%,
				rgba(0, 113, 188, 0) 70%,
				rgba(0, 113, 188, 0) 100%
			);
		}
	}
`;

// const AngleArrow = () => (
// 	<svg xmlns="http://www.w3.org/2000/svg" width="43.463" height="20.464">
// 		<path
// 			fill="#808c9899"
// 			d="M37.365.834L21.731 11.6 6.096.834C4.388-.343 2.116.196 1.026 2.038-.066 3.879.434 6.326 2.143 7.503L19.755 19.63c.602.415 1.29.622 1.976.622.687 0 1.374-.207 1.977-.622L41.32 7.503c1.708-1.177 2.209-3.624 1.117-5.465C41.344.196 39.074-.342 37.365.834z"
// 		/>
// 	</svg>
// );
export default ATSNavbar;
