import React, { useState, useEffect, useMemo, lazy, Suspense } from "react";
import { Redirect, Link } from "react-router-dom";
import FadeWrapper from "sharedComponents/FadeWrapper";
import useGlobalContext from "contexts/globalContext/GlobalContext";
import useHistoryContext from "contexts/historyContext/HistoryContext";
import { CloseBtn } from "components/ElasticSearch/CloseBtn";
import { ATSContainer } from "styles/PageContainers";
import { SearchBar } from "components/ElasticSearch/SearchBar";
import { ElasticTabs } from "components/ElasticSearch/ElasticTabs";
import { SearchResults } from "components/ElasticSearch/SearchResults";
// import notify from "notifications";
import styled from "styled-components";
import { PermissionChecker } from "constants/permissionHelpers";
import { LogoSpinner } from "styles/MainLoadingScreen";
import retryLazy from "hooks/retryLazy";
// import ParticleCloud from "styles/ParticleCloud";
import PageTitle from "sharedComponents/PageTitle";
import { WIDTHS } from "constants/style";
// import { AWS_CDN_URL } from "constants/api";
import { COLORS } from "constants/style";
import Fallback from "styles/Fallback";
import RecentSearches from "components/ElasticSearch/RecentSearches";
import { searchIndex, searchPreview } from "helpersV2/elastic";
import { addNewSearch } from "contexts/globalContext/StorageMethods";
// import Logo from "assets/imgs/hirebuddy_smiley_blue.png";
const TalentNetwork = lazy(() => retryLazy(() => import("containers/TalentNetwork")));
const DealsTable = lazy(() => retryLazy(() => import("components/ElasticSearch/DealsTable")));
// const MeetingsTable = lazy(() => retryLazy(() => import("components/ElasticSearch/MeetingsTable")));
// const DocumentsTable = lazy(() =>
//   retryLazy(() => import("components/ElasticSearch/DocumentsTable"))
// );
const ViewJobs = lazy(() => retryLazy(() => import("containers/ViewJobs")));
const ContactsManager = lazy(() =>
	retryLazy(() => import("components/ClientManager/ContactsManager"))
);
const CompaniesManager = lazy(() =>
	retryLazy(() => import("components/ClientManager/CompaniesManager"))
);
// const InterviewsTable = lazy(() =>
// 	retryLazy(() => import("components/ElasticSearch/InterviewsTable"))
// );
// const EmailsTable = lazy(() => retryLazy(() => import("components/ElasticSearch/EmailsTable")));

const BooleanInfoModal = React.lazy(() => retryLazy(() => import("modals/BooleanInfoModal")));

const ElasticSearch = ({ match }) => {
	const { company, role, session, dispatch } = useGlobalContext();
	const historyStore = useHistoryContext();
	const [searchTerm, setSearchTerm] = useState("");
	const [resultsMap, setResultsMap] = useState(null);
	const [searchPending, setSearchPending] = useState(false);
	const [activeTab, setActiveTab] = useState("_all");
	const [resultCounts, setResultCounts] = useState(null);
	const [resultsIds, setResultsIds] = useState(null);
	const [prevLocation, setPrevLocation] = useState(null);
	const [closing, setClosing] = useState(false);
	const [redirect, setRedirect] = useState(undefined);
	const [activeModal, setActiveModal] = useState(undefined);

	useEffect(() => {
		if (historyStore.current) {
			let lastValidPath = findLastPath();
			setPrevLocation(lastValidPath || `/${company?.mention_tag}/dashboard`);
		} else {
			setPrevLocation(`/${company?.mention_tag}/dashboard`);
		}
	}, [company, historyStore.current]);

	const findLastPath = () => {
		let lastValidPath;
		let index = 0;

		while (index < historyStore.state.length && !lastValidPath) {
			if (
				historyStore.state[index].url !== historyStore.current.match.url &&
				(historyStore.state.length <= 2 ||
					historyStore.state[index + 1]?.url !== historyStore.current.match.url)
			) {
				lastValidPath = historyStore.state[index];
			}
			index++;
		}
		return lastValidPath;
	};

	// useEffect(() => {
	// 	if (match.params?.tab?.length) {
	// 		const payload = { search_term: searchTerm };
	// 		searchIndex(match.params.tab, payload, company.id, session)
	// 			.then((results) => {
	// 				console.log({ results });
	// 				if (results.error || !results) return setResultsIds(null);
	// 				setResultsIds((prevIds) => {
	// 					if (prevIds)
	// 						return {
	// 							...prevIds,
	// 							[match.params.tab]: results?.map((r) => r._id),
	// 						};
	// 					return { [match.params.tab]: results && results?.map((r) => r._id) };
	// 				});
	// 			})
	// 			.catch()
	// 			.finally(() => {
	// 				setActiveTab(match.params.tab);
	// 			});
	// 	} else {
	// 		setResultsIds(null);
	// 		setActiveTab("_all");
	// 	}
	// }, [match.params, searchTerm]);

	// new fix for search bar

	useEffect(() => {
		if (match.params?.tab?.length) {
			const payload = { search_term: searchTerm };
			searchIndex(match.params.tab, payload, company.id, session)
				.then((results) => {
					console.log({ results });
					// Check if results is an array and contains elements
					if (results.error || !results || !Array.isArray(results) || !results.length) {
						return setResultsIds(null);
					}
					setResultsIds((prevIds) => {
						if (prevIds)
							return {
								...prevIds,
								[match.params.tab]: results.map((r) => r._id),
							};
						return { [match.params.tab]: results.map((r) => r._id) };
					});
				})
				.catch(() => {
					setResultsIds(null);
				})
				.finally(() => {
					setActiveTab(match.params.tab);
				});
		} else {
			setResultsIds(null);
			setActiveTab("_all");
		}
	}, [match.params, searchTerm]);

	useEffect(() => {
		const requestController = new AbortController();
		const { signal } = requestController;
		setSearchPending(true);
		if (searchTerm.length >= 2 && company) {
			// START HERE
			const payload = { search_term: searchTerm };
			// searchCount(payload, company.id, session, signal)
			//   .then(count => {
			//     if (count.error) return setResultCounts(null);
			//     const formattedCounts = count.reduce((acc, currentValue) => {
			//       const nextAcc = { ...acc };
			//       nextAcc[currentValue.key] = currentValue.doc_count;
			//       return nextAcc;
			//     }, {});

			//     if (searchTerm.length >= 2) {
			//       addNewSearch(dispatch, "elastic_search", {
			//         search: searchTerm,
			//         filters: [],
			//         results: formattedCounts,
			//         total: Object.values(formattedCounts).reduce(
			//           (acc, curr) => (acc += curr),
			//           0
			//         )
			//       });
			//     }
			//     setResultCounts(formattedCounts);
			//   })
			//   .catch();
			searchPreview(payload, company.id, session, signal)
				.then((preview) => {
					if (preview.error) return setResultsMap(null);
					const formattedCounts =
						preview && Object.keys(preview?.counts).length > 0
							? preview?.counts
							: { candidates: 0, jobs: 0, deals: 0, clients: 0, contacts: 0 };
					if (searchTerm.length >= 2) {
						addNewSearch(dispatch, "elastic_search", {
							search: searchTerm,
							filters: [],
							results: formattedCounts,
							total: Object.values(formattedCounts).reduce((acc, curr) => (acc += curr), 0),
						});
					}
					setResultCounts(formattedCounts);
					delete preview["counts"];
					setResultsMap(Object.entries(preview));
				})
				.catch();
			setSearchPending(false);
		} else {
			setResultsMap(null);
			setSearchPending(false);
			setResultCounts(null);
		}
		return () => requestController.abort();
		// eslint-disable-next-line
	}, [searchTerm, company]);

	// useEffect(() => {
	// 	if (activeTab !== "_all" && searchTerm.length >= 2 && company) {
	// 		const payload = { search_term: searchTerm };
	// 		searchIndex(activeTab, payload, company.id, session)
	// 			.then((results) => {
	// 				if (results.error) return setResultsIds(null);
	// 				setResultsIds((prevIds) => {
	// 					if (prevIds) return { ...prevIds, [activeTab]: results?.map((r) => r._id) };
	// 					return { [activeTab]: results?.map((r) => r._id) };
	// 				});
	// 			})
	// 			.catch();
	// 	} else setResultsIds(null);
	// }, [activeTab, searchTerm, company]);

	const searchBodyComponent = useMemo(() => {
		switch (activeTab) {
			case "candidates":
				return (
					<PermissionChecker type="view" valid={{ recruiter: true, hiring_manager: true }}>
						<Suspense fallback={<Fallback />}>
							<TalentNetwork as_component={true} elastic_ids={resultsIds?.candidates} />
						</Suspense>
					</PermissionChecker>
				);
			case "deals":
				return (
					<PermissionChecker type="view" valid={{ business: true }}>
						<Suspense fallback={<Fallback />}>
							<DealsTable elastic_ids={resultsIds?.deals} />
						</Suspense>{" "}
					</PermissionChecker>
				);
			// case "meetings":
			// 	return (
			// 		<PermissionChecker type="view" valid={{ business: true }}>
			// 			<Suspense fallback={<Fallback />}>
			// 				<MeetingsTable elastic_ids={resultsIds?.meetings} />
			// 			</Suspense>{" "}
			// 		</PermissionChecker>
			// 	);
			case "jobs":
				return (
					<PermissionChecker type="view" valid={{ recruiter: true, hiring_manager: true }}>
						<Suspense fallback={<Fallback />}>
							<ViewJobs as_component={true} elastic_ids={resultsIds?.jobs} />
						</Suspense>{" "}
					</PermissionChecker>
				);
			case "contacts":
				return (
					<PermissionChecker type="view" valid={{ business: true }}>
						<Suspense fallback={<Fallback />}>
							<ContactsManager elastic_ids={resultsIds?.contacts} as_component={true} />
						</Suspense>{" "}
					</PermissionChecker>
				);
			case "clients":
				return (
					<PermissionChecker type="view" valid={{ business: true }}>
						<Suspense fallback={<Fallback />}>
							<CompaniesManager elastic_ids={resultsIds?.clients} as_component={true} />
						</Suspense>{" "}
					</PermissionChecker>
				);
			// case "interviews":
			// 	return (
			// 		<PermissionChecker type="view" valid={{ recruiter: true, hiring_manager: true }}>
			// 			<Suspense fallback={<Fallback />}>
			// 				<InterviewsTable elastic_ids={resultsIds?.interviews} />
			// 			</Suspense>{" "}
			// 		</PermissionChecker>
			// 	);
			default:
				return null;
		}
	}, [activeTab, resultsIds]);

	return (
		<div className="leo-width-full">
			<PageTitle title={(company?.name || "") + " - Search"} />
			{/*activeTab === "_all" && <ParticleCloud />*/}
			<FadeWrapper className={closing ? "out" : "in"} style={{ zIndex: "10" }}>
				<SearchHeader>
					<HeaderPositioning>
						<HeaderWrapper className="leo-flex">
							<div className="leo-flex-center">
								<HeaderLogo className="leo-flex">
									<Link to={company ? `/${company.mention_tag}/dashboard` : `/companies`}>
										{/* <img
                      src={`${AWS_CDN_URL}/leo-logo-pride.svg`}
                      alt="BrandLogo"
                      className="leo-mb-5"
                    /> */}
										{/* Rebranding change: Updated logo URL for Talent-Hero
										 */}
										{/* Rebranding change: Updated logo URL for Talent-Hero
										 */}
										<img src={process.env.REACT_APP_MINI_LOGO} alt="BrandLogo" />
										{/* <img src={`${AWS_CDN_URL}/logos/plac-d-gradient-dots.png`} alt="BrandLogo" /> */}
										{/*}<img
											src={`${AWS_CDN_URL}/leo-logo-color.svg`}
											alt="BrandLogo"
											className="leo-mb-5"
										/>*/}
									</Link>
								</HeaderLogo>
								<SearchBarContainer>
									<SearchBar setSearch={setSearchTerm} search={searchTerm} match={match} />
								</SearchBarContainer>
							</div>
							<div>
								<HowToButton
									className="info-button"
									onClick={() => setActiveModal("boolean-info-modal")}
								>
									How to use Power Search
								</HowToButton>
								<button
									onClick={() => {
										setClosing(true);
										setTimeout(function () {
											setRedirect(prevLocation);
										}, 500);
									}}
								>
									<CloseBtn />
								</button>
							</div>
						</HeaderWrapper>
					</HeaderPositioning>
				</SearchHeader>
				<ATSContainer>
					<ElasticTabs
						activeTab={activeTab}
						resultCounts={resultCounts}
						company={company}
						role={role}
						historyStore={historyStore}
					/>
				</ATSContainer>
				<div>
					{!searchPending ? (
						activeTab === "_all" ? (
							<>
								{searchTerm === "" ? (
									<RecentSearches setSearch={setSearchTerm} origin="elastic_search" />
								) : (
									<SearchResults results={resultsMap} role={role} />
								)}
							</>
						) : null
					) : (
						activeTab === "_all" && (
							<SearchingContainer>
								<div className="leo-relative">
									<LogoSpinner />
								</div>
							</SearchingContainer>
						)
					)}
					<TableWrapper>{searchBodyComponent}</TableWrapper>
				</div>
				{redirect && <Redirect to={redirect} />}
			</FadeWrapper>
			{activeModal === "boolean-info-modal" && (
				<Suspense fallback={<Fallback modal={true} />}>
					<BooleanInfoModal hide={() => setActiveModal(undefined)} />
				</Suspense>
			)}
		</div>
	);
};

export default ElasticSearch;

const SearchHeader = styled.div`
	background: ${COLORS.white};
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 10;
	height: ${WIDTHS.top_navbar};
	border-bottom: 1px solid #dfe1e6;
	display: flex;
	box-shadow: 0px 1px 4px rgba(223, 225, 230, 0.5);
`;

const SearchBarContainer = styled.div`
	align-items: center;
	display: flex;
	height: 50px;
	justify-content: space-between;
`;

const TableWrapper = styled.div`
	margin-top: 70px;
`;

const SearchingContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100vw;
`;

const HowToButton = styled.button`
	width: max-content;
	font-size: 12px;
	line-height: 15px;
	color: ${COLORS.text_medium_grey};
	margin-right: 10px;

	&:hover {
		text-decoration: underline;
	}
`;

const HeaderPositioning = styled.div`
	width: 100%;
	margin-left: 150px;
	margin-right: 35px;
`;

const HeaderWrapper = styled.div`
	align-items: center;
	height: ${WIDTHS.top_navbar};
	justify-content: space-between;
`;

const HeaderLogo = styled.div`
	align-items: center;

	img {
		height: 50px;
		width: 50px;
	}
`;
