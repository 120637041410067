import React from "react";
import styled from "styled-components";
// import Loader from "react-loader-spinner";
// import { AWS_CDN_URL } from "constants/api";
import { COLORS } from "constants/style";

const Spinner = ({ color, size, inline, style, className }) => (
	<SpinnerContainer
		inline={inline}
		style={style}
		className={`${className} leo-relative leo-flex-center-center`}
	>
		<CircleLoader
			color={color || COLORS.primary_theme_blue}
			height={size === "sm" ? 16 : 32}
			width={size === "sm" ? 16 : 32}
			position="inline"
		/>
		{/*}<Loader
			type="Oval"
			color={color || COLORS.primary_theme_blue}
			height={size === "sm" ? 16 : 32}
			width={size === "sm" ? 16 : 32}
		/>*/}
	</SpinnerContainer>
);

export default Spinner;

const SpinnerContainer = styled.div`
	display: ${(props) => (props.inline ? "inline" : "flex")};
	align-items: center;
	justify-content: center;
	padding: ${(props) => (!props.inline ? "20px 0px" : "0px")};
`;

export const LogoSpinner = () => (
	<LogoContainer>
		<CircleLoader />
		{/* Rebranding change: Updated logo URL for Talent-Hero
		 */}
		<img src={process.env.REACT_APP_MINI_LOGO} alt="BrandLogo" />
		{/* <img src={`${AWS_CDN_URL}/logos/plac-d-gradient-dots.png`} alt="BrandLogo" /> */}
	</LogoContainer>
);

const CircleLoader = styled.div`
	position: {props => props.position || "absolute"};
	margin-bottom: $loader-size/2;
	border: 1px solid rgba(130, 130, 130, 0.36);
	border-left-color: ${(props) => props.color || "#eee"};
	animation: loader-spin 1.2s infinite linear;
	display: inline-block;
	vertical-align: top;
	border-radius: ${(props) => props.width || "100"}px;
	width: ${(props) => props.width || "100"}px;
	height: ${(props) => props.height || "100"}px;
	top: -30px;
	left: -20px;

	@keyframes loader-spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`;
const LogoContainer = styled.div`
	position: relative;

	img {
		height: 40px;
		opacity: 0.7;
		width: 60px;
	}
`;

// import Spinner from "sharedComponents/Spinner";
