import { useState, useEffect } from "react";
import useGlobalContext from "contexts/globalContext/GlobalContext";
import spacetime from "spacetime";
import { allowAdminAccount } from "./AllowAdminFunc";

export const moveDate7DaysAhead = (data) => {
	const time = spacetime(data);
	const moveTime = time.add(14, "day");
	return moveTime.format("YYYY-MM-DDTHH:mm:ss.SSS");
};

export const isTrailFin = (data, status) => {
	return (
		spacetime(spacetime.tomorrow()).diff(moveDate7DaysAhead(data), "day") < 0 && status === "trial"
	);
};

export const subscriptionChecker = (subscription_settings, validSubscriptions, session) => {
	const adminAllow = allowAdminAccount(session);
	let answer = adminAllow ? true : false;
	if (validSubscriptions[subscription_settings?.status]) {
		answer = true;
	}
	if (isTrailFin(subscription_settings.created_at, subscription_settings.status) && !adminAllow) {
		answer = false;
	}
	return answer;
};

const SubscriptionChecker = ({ valid, onFalse, children }) => {
	const store = useGlobalContext();
	const [allowed, setAllowed] = useState(undefined);
	useEffect(() => {
		if (store.role) {
			setAllowed(subscriptionChecker(store.subscription_settings, valid, store.session));
		}
	}, [store.role]);

	useEffect(() => {
		const period_end = spacetime(store.subscription_settings?.current_period_end);
		if (
			store.subscription_settings.status === "cancelled" &&
			spacetime.now().isBefore(period_end) &&
			!allowed
		) {
			setAllowed(true);
		}

		if (store.subscription_settings.status === "cancelled" && spacetime.now().isAfter(period_end)) {
			setAllowed(false);
		}
	}, [allowed, store.subscription_settings]);

	if (allowed) {
		return children;
	} else if (!allowed && onFalse) {
		return onFalse();
	} else {
		return null;
	}
};

export default SubscriptionChecker;
