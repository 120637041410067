import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import notify from "notifications";

const { S3Client, GetObjectCommand } = require("@aws-sdk/client-s3");

const client = new S3Client({
	region: "eu-west-2",
	credentials: {
		accessKeyId: process.env.REACT_APP_MICROSERVICES_AWS_ACCESSKEY,
		secretAccessKey: process.env.REACT_APP_MICROSERVICES_AWS_SECRET_ACCESSKEY,
	},

	// Add these settings to help with bundling issues
	// apiVersion: "2006-03-01",
	// maxAttempts: 3,
	// logger: console,
});

// console.error("AWS Config:", {
// 	region: "eu-west-2",
// 	hasAccessKey: !!process.env.REACT_APP_MICROSERVICES_AWS_ACCESSKEY,
// 	hasSecretKey: !!process.env.REACT_APP_MICROSERVICES_AWS_SECRET_ACCESSKEY,
// });

// export const getSignedURL = async (url, BUCKET, setUrl, showError, errorText) => {
// 	if (!url.includes("https://")) return url;
// 	if (url.includes(`https://${BUCKET}`)) return url;
// 	const key = url.split(`/${BUCKET}/`)[1].split("?")[0];
// 	const command = new GetObjectCommand({ Bucket: BUCKET, Key: decodeURIComponent(key) });
// 	try {
// 		const url = await getSignedUrl(client, command, { expiresIn: 3600 });
// 		if (setUrl) setUrl(url);
// 		return url;
// 	} catch (error) {
// 		console.log(error);
// 		if (showError) {
// 			notify("danger", errorText || "Unable to fetch resource.");
// 		}
// 		return url;
// 	}
// };

// export const getSignedURL = async (
// 	url,
// 	BUCKET,
// 	setUrl = null,
// 	showError = null,
// 	errorText = null
// ) => {
// 	if (!url?.includes("https://")) return url;

// 	try {
// 		// Simplify the key extraction
// 		const urlPath = url.split(BUCKET + "/")[1];
// 		if (!urlPath) {
// 			console.error("Could not extract path from URL");
// 			return url;
// 		}

// 		const key = urlPath.split("?")[0]; // Remove query parameters

// 		// Log for debugging
// 		console.log("Attempting to sign URL:", {
// 			bucket: BUCKET,
// 			key,
// 			originalUrl: url,
// 		});

// 		// Create the command with explicit parameters
// 		const command = new GetObjectCommand({
// 			Bucket: BUCKET,
// 			Key: key,
// 		});

// 		// Generate signed URL with error catching
// 		const signedUrl = await getSignedUrl(client, command, {
// 			expiresIn: 3600,
// 		}).catch((err) => {
// 			console.error("getSignedUrl internal error:", err);
// 			throw err;
// 		});

// 		// Update state if callback provided
// 		if (setUrl && typeof setUrl === "function") {
// 			setUrl(signedUrl);
// 		}

// 		return signedUrl;
// 	} catch (error) {
// 		console.error("Error in getSignedURL:", error);

// 		if (showError) {
// 			notify("danger", errorText || "Unable to fetch resource.");
// 		}

// 		return url;
// 	}
// };

export const getSignedURL = async (
	url,
	BUCKET,
	setUrl = null,
	showError = null,
	errorText = null
) => {
	if (!url?.includes("https://")) return url;

	try {
		// Splitting the URL to get parts before and after the bucket
		const parts = url.split(BUCKET + "/");
		if (parts.length !== 2) {
			console.error("Could not extract path from URL");
			return url;
		}

		// path after the bucket and before any query parameters
		const urlPath = parts[1].split("?")[0];

		// decode the URL-encoded path
		let decodedPath;
		try {
			// General URL encoding
			decodedPath = decodeURIComponent(urlPath);

			// remaining encoded characters - any types
			decodedPath = decodedPath
				// plus signs that might represent spaces
				.replace(/\+/g, " ")
				// double-encoded characters
				.replace(/%25/g, "%")
				// specific special characters that might cause issues
				.replace(/%2F/g, "/")
				.replace(/%2C/g, ",")
				.replace(/%23/g, "#")
				.replace(/%26/g, "&")
				.replace(/%40/g, "@")
				.replace(/%3A/g, ":")
				.replace(/%3B/g, ";")
				.replace(/%3D/g, "=")
				.replace(/%3F/g, "?")
				.replace(/%5B/g, "[")
				.replace(/%5D/g, "]")
				.replace(/%7B/g, "{")
				.replace(/%7D/g, "}");
		} catch (decodeError) {
			console.error("Error decoding URL path:", decodeError);
			// Fallback to original path if decoding fails
			decodedPath = urlPath;
		}

// 		console.log("Attempting to sign URL:", {
// 			bucket: BUCKET,
// 			key: decodedPath,
// 			originalUrl: url,
// 		});

		const command = new GetObjectCommand({
			Bucket: BUCKET,
			Key: decodedPath,
		});

		const signedUrl = await getSignedUrl(client, command, {
			expiresIn: 3600,
		}).catch((err) => {
			console.error("getSignedUrl internal error:", err);
			throw err;
		});

		if (setUrl && typeof setUrl === "function") {
			setUrl(signedUrl);
		}

		return signedUrl;
	} catch (error) {
		console.error("Error in getSignedURL:", error);

		if (showError) {
			notify("danger", errorText || "Unable to fetch resource.");
		}

		return url;
	}
};
// export const getSignedURL = async (url, BUCKET, setUrl, showError, errorText) => {
// 	const tmpURL = url;
// 	setUrl(tmpURL);
// 	if (!url.includes("https://")) return url;
// 	if (url.includes(`https://${BUCKET}`)) return url;

// 	const key = url.split(`/${BUCKET}/`)[1].split("?")[0];
// 	const command = new GetObjectCommand({ Bucket: BUCKET, Key: decodeURIComponent(key) });
// 	try {
// 		const url = await getSignedUrl(client, command, { expiresIn: 3600 });
// 		// if (setUrl) setUrl(url);
// 		return url;
// 	} catch (error) {
// 		console.log(error);
// 		if (showError) {
// 			notify("danger", errorText || "Unable to fetch resource.");
// 		}
// 		// return url;
// 	}
// 	return tmpURL;
// };
