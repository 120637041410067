import React, { useState, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import AvatarIcon from "sharedComponents/AvatarIcon";
import { ROUTES } from "routes";
import Scrollbar from "sharedComponents/Scrollbar";
import RotateItem from "styles/RotateItem";
import { COLORS, COLORSV2 } from "constants/style";
import { removeStorageSearches } from "contexts/globalContext/StorageMethods";

const CompanySelect = ({ expandedView, store, node, showSelect, setShowSelect }) => {
	const [val, setVal] = useState("");
	const toggleSelect = () => setShowSelect(!showSelect);
	const [search, setSearch] = useState("");
	const options = useMemo(
		() =>
			store.allMyCompanies
				? store.allMyCompanies.filter((comp) =>
						comp.name.toLowerCase().includes(search.toLowerCase())
				  )
				: [],
		[store.allMyCompanies, search]
	);

	useEffect(() => {
		if (showSelect) {
			document.getElementById("company-search-input")?.focus();
		} else if (val !== "") {
			setVal("");
			setSearch("");
		}
	}, [showSelect]);

	useEffect(() => {
		const delayInputTimeoutId = setTimeout(() => {
			setSearch(val);
		}, 500);
		return () => clearTimeout(delayInputTimeoutId);
	}, [val]);

	return (
		<NavBarContainer className="leo-pl-30 leo-pb-20 leo-pr-5" ref={node}>
			<AvatarIcon
				name={store.company?.name}
				imgUrl={store.company?.avatar_url}
				size={35}
				shape="square"
				className="leo-mr-25"
			/>
			{expandedView && (
				<CompanySelectButton onClick={toggleSelect} disabled={store.allMyCompanies?.length < 2}>
					<CompanyName>{store.company?.name}</CompanyName>
					{store.allMyCompanies?.length > 1 && (
						<RotateItem newPos={!showSelect ? "down" : "up"}>
							<i className="fas fa-caret-down" />
						</RotateItem>
					)}
				</CompanySelectButton>
			)}
			{showSelect && (
				<MenuContainer className="leo-pt-20">
					{store.allMyCompanies.length > 10 && (
						<SearchContainer className="leo-ml-30 leo-mr-30 leo-mb-10">
							<SearchIcon />
							<CompanySearchInput
								value={val}
								onChange={(e) => setVal(e.target.value)}
								placeholder="Search company"
								id="company-search-input"
							/>
						</SearchContainer>
					)}
					<Scrollbar>
						<ListContainer className="leo-pt-20">
							{options.map((company) => (
								<RowContainer className="leo-ml-30 leo-mb-30" key={`company-${company.id}`}>
									<STLink
										onClick={() => {
											toggleSelect();
											localStorage.getItem("stored_searches") &&
												localStorage.removeItem("stored_searches");
											removeStorageSearches(store.dispatch);
										}}
										to={ROUTES.CompanyDashboard.url(company.mention_tag)}
										className="leo-flex-center"
									>
										<AvatarIcon
											name={company.name}
											imgUrl={company.avatar_url}
											size={35}
											shape="square"
											className="leo-mr-25"
										/>
										<span>{company.name}</span>
									</STLink>
								</RowContainer>
							))}
						</ListContainer>
					</Scrollbar>
				</MenuContainer>
			)}
		</NavBarContainer>
	);
};

export default CompanySelect;

const NavBarContainer = styled.div`
	width: 100%;
	position: relative;
	display: flex;
	align-items: center;
	border-bottom: solid 1px ${COLORS.suplement_medium_grey};
`;

const CompanySelectButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	font-size: 15px;
	line-height: 18px;
	color: ${COLORS.white};

	&:disabled {
		cursor: default;
	}

	i {
		font-size: 20px;
	}
`;

const CompanyName = styled.span`
	white-space: nowrap;
	max-width: 80px;
	text-overflow: ellipsis;
	overflow: hidden;
	margin-right: 5px;
`;

const MenuContainer = styled.div`
	position: absolute;
	height: calc(100vh - 155px);
	width: 100%;
	/* background: linear-gradient(0deg, ${COLORSV2.blue} -0.01%, ${COLORSV2.nave_blue} 100.01%); */
	background: ${COLORSV2.nave_blue};
	top: calc(100% + 1px);
	left: 0;
	z-index: 1;
`;

const SearchContainer = styled.div`
	position: relative;
	border: 1px solid ${COLORS.suplement_medium_grey};
	border-radius: 10px;
	padding: 10px 15px;
	display: flex;
	align-items: baseline;

	svg {
		margin-right: 10px;
	}
`;

const CompanySearchInput = styled.input`
	border: none;
	padding: 0px;
	background: none;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: ${COLORS.white};
	width: 140px;

	&::placeholder {
		color: ${COLORS.white};
		opacity: 1;
	}
`;

const RowContainer = styled.div`
	font-weight: 500;
	font-size: 15px;
	line-height: 18px;
	color: ${COLORS.white};

	span {
		max-width: 160px;
	}
`;

const STLink = styled(Link)`
	color: inherit;
	width: 100%;

	&:hover {
		text-decoration: none;
		color: inherit;
	}
`;

const ListContainer = styled.div`
	max-height: calc(100vh - 200px);
`;

const SearchIcon = () => (
	<svg width="15" height="15" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M14.742 13.494l-3.113-3.113a6.471 6.471 0 10-1.248 1.248l3.112 3.113a.88.88 0 001.248 0 .882.882 0 000-1.248zm-8.27-2.316c-1.258 0-2.44-.49-3.329-1.379a4.675 4.675 0 01-1.378-3.328c0-1.257.49-2.439 1.378-3.328a4.674 4.674 0 013.328-1.378c1.257 0 2.44.49 3.328 1.378a4.674 4.674 0 011.379 3.328A4.67 4.67 0 019.798 9.8a4.668 4.668 0 01-3.327 1.378z"
			fill="#fff"
		/>
	</svg>
);
