// old
// export const COLORS = {
// 	white: "#FFFFFF",
// 	black: "#000000",
// 	grey: "#EEEEEE",
// 	text_black: "#1E1E1E",
// 	text_dark_grey: "#74767B",
// 	text_medium_grey: "#818D99",
// 	text_light_grey: "#B0BDCA",
// 	primary_theme_blue: "#2A3744",
// 	primary_blue: "#0892C1",
// 	primary_light_blue: "#8CD4ED",
// 	primary_green: "#35C3AE",
// 	primary_light_green: "#00CBA7",
// 	primary_orange: "#FFA076",
// 	primary_purple: "#BDC4F4",
// 	primary_yellow: "#F4D16E",
// 	primary_red: "#F27881",
// 	secondary_blue: "#C5E9F6",
// 	secondary_green: "#C9F7E5",
// 	secondary_orange: "#FFE7DD",
// 	secondary_purple: "#E5E7FB",
// 	secondary_pink: "#FEF1F2",
// 	suplement_light_grey: "#DFE9F4",
// 	suplement_medium_grey: "#D4DFEA",
// 	suplement_dark_grey: "#B0BDCA",
// };
// ........new
export const COLORS = {
	white: "#FFFFFF",
	black: "#000000",
	grey: "#e4e4e4",
	text_black: "#1E1E1E",
	text_dark_grey: "#74767B",
	text_medium_grey: "#818D99",
	text_light_grey: "#B0BDCA",
	primary_theme_blue: "#1f1b65",
	primary_blue: "#5b2bcb",
	primary_light_blue: "#51bae7",
	primary_green: "#35C3AE",
	primary_light_green: "#00CBA7",
	primary_orange: "#FFA076",
	primary_purple: "#BDC4F4",
	primary_yellow: "#feb102",
	primary_red: "#ff3366",
	secondary_blue: "#C5E9F6",
	secondary_green: "#C9F7E5",
	secondary_orange: "#FFE7DD",
	secondary_purple: "#E5E7FB",
	secondary_pink: "#e4e4e4",
	suplement_light_grey: "#DFE9F4",
	suplement_medium_grey: "#D4DFEA",
	suplement_dark_grey: "#B0BDCA",
};
// .............. new
export const COLORSV2 = {
	nave_blue: "#1f1b65",
	gray: "#AAC9D6",
	// blue: "#0071BC",
	blue: "#5b2bcb", // new
	// light_blue: "#11CAD6",
	light_blue: "#51bae7", // new
	orange: "#FB8F53",
	yellow: "#feb102",
	sky_blue: "#42AEC2",
	light_green: "#9EE89D",
	red: "#ff3366",
	pink: "#e4e4e4", // grey pink
};

// .........old
// export const COLORSV2 = {
// 	nave_blue: "#063149",
// 	gray: "#AAC9D6",
// 	// blue: "#0071BC",
// 	blue: "#1f1b65", // new
// 	// light_blue: "#11CAD6",
// 	light_blue: "#5b2bcb", // new
// 	orange: "#FB8F53",
// 	yellow: "#F7C541",
// 	sky_blue: "#42AEC2",
// 	light_green: "#9EE89D",
// 	red: "#FF2A4E",
// 	pink: "#FF717A",
// };

export const BORDERS = {
	grey_thin: "solid #e7e7e7 1px",
	dark_grey_thin: "solid #c4c4c4 1px",
	blue_thin: "solid #0071BC 1px",
};

export const WIDTHS = {
	top_navbar: "50px",
	left_navbar_compact: "95px",
	left_navbar_extended: "200px",
	left_navbar_large: "260px",
};

const supportsFlagEmoji = () => {
	if (process.env.NODE_ENV !== "test") {
		let canvas = document.createElement("canvas");
		canvas.height = 10;
		canvas.width = canvas.height * 2;
		let ctx = canvas.getContext("2d");
		ctx.font = canvas.height + "px Arial";
		ctx.fillText("🇬🇧", 0, canvas.height);
		let data = ctx.getImageData(0, 0, canvas.width, canvas.height).data;
		let i = 0;
		while (i < data.length) {
			if (data[i] !== data[i + 1] || data[i] !== data[i + 2]) return true;
			i += 4;
		}
	}
	return false;
};

export const SUPPORT_EMOJI = supportsFlagEmoji();
