import React, { useState, useEffect } from "react";
import useGlobalContext from "contexts/globalContext/GlobalContext";
import styled from "styled-components";
// import { AWS_CDN_URL } from "constants/api";
import { BORDERS } from "constants/style";
import { COLORS } from "constants/style";
import { ROUTES } from "routes";
// import Logo from "assets/imgs/hirebuddy_smiley_blue.png"

const MainLoadingScreen = ({ historyStore }) => {
	const store = useGlobalContext();
	const [hasSession, setHasSession] = useState(false);
	const [allowClickOut, setAllowClickOut] = useState(false);
	const [fadeOut, setFadeOut] = useState(undefined);
	const [hide, setHide] = useState(undefined);

	useEffect(() => {
		if (store.session) {
			setHasSession(true);
			setTimeout(() => setAllowClickOut(true), 10000);
		} else {
			setHasSession(false);
			setHide(false);
			setFadeOut(undefined);
		}
	}, [store.session]);

	useEffect(() => {
		if (
			!hide &&
			store.session &&
			store.user &&
			historyStore.current &&
			(historyStore.current.match.path === ROUTES.MyCompanies.path ||
				historyStore.current.match.path === ROUTES.NewPassword.path)
		) {
			setFadeOut(true);
		}
	}, [historyStore.current, store.user, store.session, hide]);

	useEffect(() => {
		if (fadeOut) {
			setTimeout(() => setHide(true), 500);
		}
	}, [fadeOut]);

	useEffect(() => {
		if (Object.values(store.main_endpoints_load).every((val) => val)) {
			setFadeOut("fade_out");
		}
	}, [store.main_endpoints_load]);

	const clickOut = () => {
		if (allowClickOut) {
			setFadeOut(true);
		}
	};
	return (
		<>
			{!hasSession || hide ? null : (
				<Container id="main-loading-screen" className={fadeOut} onClick={clickOut}>
					<LogoSpinner />
				</Container>
			)}
		</>
	);
};

// const logoUrl = `${AWS_CDN_URL}/logos/plac-d-gradient-dots.png`; //`${AWS_CDN_URL}/leo-logo-pride.svg`; //`${AWS_CDN_URL}/leo-logo-color.svg`;

//  Rebranding change: Updated logo URL for Talent-Hero

const logoUrl = process.env.REACT_APP_MINI_LOGO; //`${AWS_CDN_URL}/leo-logo-pride.svg`; //`${AWS_CDN_URL}/leo-logo-color.svg`;

export const LogoSpinner = () => (
	<LogoContainer>
		<CircleLoader />
		<img src={logoUrl} alt="BrandLogo" />
	</LogoContainer>
);

const Container = styled.div`
	height: 100vh;
	width: 100vw;
	position: fixed;
	z-index: 10000;
	background: ${COLORS.white};
	display: flex;
	align-items: center;
	justify-content: center;
	&.fade_out {
		animation: fadeout 0.5s;
		animation-fill-mode: forwards;
	}

	@keyframes fadeout {
		from {
			opacity: 1;
		}
		to {
			opacity: 0;
		}
	}
`;

const LogoContainer = styled.div`
	position: relative;

	img {
		position: absolute;
		/* right: 18px;
    top: 35px; */
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 50%;
	}
`;

const CircleLoader = styled.div`
	/* position: absolute; */
	/* margin-bottom: $loader-size/2; */
	border: ${BORDERS.dark_grey_thin};
	border-left-color: ${COLORS.primary_theme_blue};
	animation: loader-spin 1.2s infinite linear;
	display: inline-block;
	vertical-align: top;
	border-radius: 100px;
	width: 100px;
	height: 100px;
	/* top: -33px;
  left: -17px; */

	@keyframes loader-spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`;

export default MainLoadingScreen;
